import React from "react"
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core/styles';
import {Button, Card, Grid, Typography, FormControl, Select, MenuItem, InputLabel, TextField, CardContent, Paper} from "@material-ui/core"
import Layout from "../../components/layout"
import GetCompanies from '../../api/tickets/getCompanies'
import Access from '../../api/rh/index'
import { navigate } from "gatsby"

const styles = theme =>({

    margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'center', 
    },
    ListItemText: {
        textAlign: 'center',    
    },
    inputCompanie:{
        //width: '50%',
        margin: theme.spacing(1),
        minWidth: 210,

    },
    grid:{
       paddingTop: '12%'
    },
    paper:{
        
    },
    card:{        
    }
    
    
  });

  class RHAccess extends React.Component {
    constructor() {
        super();
        // No hagas esto!
        this.state = { 
            companyUuid: '',
            token: '',
            companies: [],
            companyName: ''
        }
    }
    handleChange = input => event => {
        event.preventDefault();
        //console.log(event.nativeEvent.srcElement.innerText)
        this.setState({[input]: event.target.value});
        if(input==='companyUuid'){
            this.setState({companyName: event.nativeEvent.srcElement.innerText});
        }
      };

    async componentDidMount(){
        const companies = await GetCompanies();
        console.log(companies)
            this.setState({
                companies,
                companyUuid: companies[0].uuid,
                companyName: companies[0].name
            })
    }
    render(){
        const { classes } = this.props;
        const RHLog = async()=>{
         if(this.state.token!==''){
            const newToken = await Access(this.state.companyUuid, this.state.token)
            if(newToken.status === 'success'){
                navigate('/padron/subir/census/',
                  {
                    state: { 
                        token: newToken.data.token,
                        companyName: this.state.companyName,
                        companyUuid: this.state.companyUuid
                         },
                  })
            }else{
                alert(newToken.data.message);
            }

         }else{
            alert('Ingrese su contraseña')
         }
        }
       
    return(
      <Layout>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
            >
          <Paper className={classes.paper}>
             <Card className={classes.card} variant="outlined">
              <CardContent>
                <Typography className={classes.margin} variant="h5">Acceso RH</Typography>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    {this.state.companies.length > 0 ? ( 
                        <FormControl variant="outlined" className={classes.inputCompanie}>
                            <InputLabel id="demo-simple-select-outlined-label">Seleccione su Empresa</InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.companyUuid}
                            defaultValue={''}
                            //name='CompanyUuid'
                            onChange={this.handleChange('companyUuid')}
                            labelWidth= {169}
                            >
                                {this.state.companies.map((company,index) => {
                                    return (
                                        <MenuItem value={company.uuid} key={company.uuid}>{company.name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        ) : (                              
                        <FormControl variant="outlined" className={classes.inputCompanie}>
                            <InputLabel id="demo-simple-select-outlined-label">Seleccione su Empresa</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                labelWidth= {169}
                                >
                                <MenuItem value={0}>_</MenuItem>
                                </Select>
                        </FormControl>
                        )}
                        <TextField 
                            className={classes.margin} 
                            id="outlined-basic" variant="outlined" 
                            label='Contraseña' 
                            inputProps={{ style: { textAlign: 'center' }}}
                            type="password"
                            onChange={this.handleChange('token')}
                            defaultValue={this.state.token}
                        />
                        <Button variant="contained" color="primary" component="span"  onClick={RHLog}>
                            Acceder
                        </Button>
                </Grid>
             </CardContent>
            </Card>
        </Paper>
        </Grid>
      </Layout>
    )
  }
}
RHAccess.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withStyles(styles)(RHAccess)
  