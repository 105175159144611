import api from "../baseurl";

const access = async (companyUuid, token) => {
    //console.log(data)
    const status = await api.post("rhs/login", {json: {
        rhPassword:token,
        uuid: companyUuid,
    }}).json();
    return status ;
    
  };
  
  export default access ; 